.wrapper {
  display: flex;
  width: 72px;
  height: 72px;
  justify-content: center;
  align-items: center;
  background: var(
    --Shepherd-Gradient,
    linear-gradient(90deg, #fdc331 0%, #fa9742 39.76%, #da43e7 100%)
  );

  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
}

.root {
  display: flex;
  width: calc(100% - 2.5px);
  height: calc(100% - 2.5px);
  justify-content: center;
  align-items: center;

  background: linear-gradient(180deg, #525252 0%, #000 100%);
}
