.wrapper {
  z-index: 1;
  position: relative;
  border-radius: var(--mantine-radius-xl);
  width: fit-content;

  &:has(button:active) {
    transform: translateY(calc(0.0625rem * var(--mantine-scale)));
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(
      --Shepherd-Gradient,
      linear-gradient(90deg, #fdc331 0%, #fa9742 39.76%, #da43e7 100%)
    );
    /* filter: blur(25px); */
  }

  .glow {
    background: var(
      --Shepherd-Gradient,
      linear-gradient(90deg, #fdc331 0%, #fa9742 39.76%, #da43e7 100%)
    );
    border-radius: var(--mantine-radius-xl);
    filter: blur(25px);
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

/* .wrapper {
  display: flex;

  justify-content: center;
  align-items: center;
  background: var(
    --Shepherd-Gradient,
    linear-gradient(90deg, #fdc331 0%, #fa9742 39.76%, #da43e7 100%)
  );

  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
  font: inherit;
  border-radius: 10000px;
  white-space: nowrap;
} */

/* .root {
  border-radius: 10000px;
  display: flex;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  padding: 12px 16px 12px 24px;
  gap: 4px;
  background: linear-gradient(180deg, #525252 0%, #000 100%);
} */

/* .content {
  color: #fff;
  text-align: center;
  font-family: Inter Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
} */

.root {
  position: relative;

  &:active {
    transform: none;
  }

  :global(.mantine-Button-section) {
    transition: margin-left 0.15s;
  }

  &:hover {
    background-color: blue;
    :global(.mantine-Button-section) {
      margin-left: calc(var(--_button-section-margin-left) + 5px);
    }
  }
  /* overflow: visible; */
}
