.root {
  border-radius: 24.975px;
  border: 1.041px solid rgba(0, 0, 0, 0.16);
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 0px 0px 24.975px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  padding: 36px;
  align-items: flex-start;

  img {
    border-radius: 24px;
  }

  @media (max-width: $mantine-breakpoint-sm) {
    padding: 12px;

    border-radius: 12px;

    img {
      border-radius: 12px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.screenGradient {
  background: var(
    --Shepherd-Gradient,
    linear-gradient(90deg, #fdc331 0%, #fa9742 39.76%, #da43e7 100%)
  );
  filter: blur(250px);
  position: absolute;
  z-index: 1;
  height: 50%;

  animation-duration: 1.5s;
  animation-name: fadeIn;

  @media (max-width: $mantine-breakpoint-sm) {
    height: 60px;
    filter: blur(150px);
  }
}
