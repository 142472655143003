.container {
  overflow: hidden;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    bottom: -20px;
    top: -20px;
    width: 10vw;
    background: linear-gradient(90deg, #fff 30%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;

    @media (max-width: $mantine-breakpoint-xs) {
      width: 10vw;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 0px;
    bottom: -20px;
    top: -20px;
    width: 10vw;
    background: linear-gradient(270deg, #fff 30%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;

    @media (max-width: $mantine-breakpoint-xs) {
      width: 10vw;
    }
  }
}

.track {
  animation: logoloop 60s linear infinite;
}

@media (prefers-reduced-motion) {
  .track {
    animation-play-state: paused;
  }
}

@keyframes logoloop {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
