.blur {
  /* border-radius: 960px; */
  height: 700px;
  width: 100%;
}

.image {
  width: 100vw;
  height: 700px;
  position: absolute;
  bottom: -250px;

  @media (max-width: $mantine-breakpoint-sm) {
    width: 200vw;
    height: 500px;
  }
}

/* @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .blur {
    background: var(
      --Shepherd-Gradient,
      linear-gradient(90deg, #fdc331 0%, #fa9742 39.76%, #da43e7 100%)
    ) !important;
    filter: blur(250px);
    width: 50%;
    height: 220px;
  }
} */
