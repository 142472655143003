.span {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 100%;
  padding-bottom: var(--mantine-spacing-xl);

  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: var(--mantine-spacing-lg);
  }
}
