.wrap {
  position: relative;
  width: 100%;
  overscroll-behavior: contain;
}

.headers {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

.tracks,
.scroller {
  display: flex;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.scroller {
  overflow-x: hidden;
}

.tracks {
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.scenes::-webkit-scrollbar,
.scroller::-webkit-scrollbar {
  display: none;
}

.track {
  flex: 1 0;
  scroll-snap-align: start;
}

.trackLarger {
  flex: 1 0 40px;
}

.track + .track {
  margin-left: -1px;
}

.heading {
  height: 70px;
  display: flex;

  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
  z-index: 1;
  background: var(--mantine-color-shepherdGray-0);
  font-weight: 700;
  width: 100%;
  padding: 12px 10px;
}

.entry {
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
  display: flex;
  align-items: center;
  border-top: 0;
  background: #fff;
  padding: 12px 10px;
  height: 50px;
  width: 100%;
}

@media (max-width: 767px) {
  .track {
    flex: 1 0 calc(50% + 7px);
  }
}

.borderTopLeft {
  border-top-left-radius: 8px;
}
.borderTopRight {
  border-top-right-radius: 8px;
}
.borderBottomLeft:last-child {
  border-bottom-left-radius: 8px;
}
.borderBottomRight:last-child {
  border-bottom-right-radius: 8px;
}
